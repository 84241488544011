@media(min-width: 1280px){
.header__nav-link--call-us.header__nav-link--call-us{
    color:#ee4981;
    cursor:pointer;
    position:relative;
    top:1px
}
.header__nav-link--call-us.header__nav-link--call-us:visited{
    color:#ee4981
}
.header__nav-link--call-us.header__nav-link--call-us:hover{
    color:#ee4981;
    opacity:.7
}
}