.header__link-catalog{
  margin-left:auto;
  font-weight:600;
  font-size:12px;
  color:#373737 !important;
  border-bottom:none
}
.header__link-catalog:hover{
  border-bottom:none
}
@media(min-width: 1280px){
.header__phone-wrapper--cart{
    margin-left:70px
}
.header__container--cart{
    padding-top:0;
    min-height:147px;
    align-items:center;
    max-width:1223px
}
.header__nav-link--cart{
    margin-left:70px
}
.header__logo-link--cart{
    padding-bottom:25px
}
}