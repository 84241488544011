html{
  font-family:"Open Sans",sans-serif;
  height:100%
}
body{
  margin:0
}
body.noScroll{
  overflow:hidden;
  height:100%
}
.header{
  display:none
}
.mobile-header-sticky{
  position:-webkit-sticky;
  position:sticky;
  top:0;
  z-index:1000
}
@media(min-width: 1280px){
.mobile-header-sticky{
    position:relative
}
}
.fixed-header__wrapper{
  display:none
}
.phone-showall-button{
  height:100%;
  position:absolute;
  right:0;
  top:0;
  padding:0 7px;
  background-color:#00aafa;
  color:#fff;
  border:none;
  cursor:pointer;
  outline:none;
  width:auto;
  line-height:normal;
  font-size:13px;
  border-radius:0
}
.phone-showall-button:hover{
  background-color:#00aafa;
  color:#fff
}
.select2-container{
  z-index:10000000000;
  font-size:13px
}
.select2-container .select2-selection{
  outline:none;
  border-radius:0;
  border:1px solid #e1e1e1
}
.select2-container input{
  outline:none
}
@media(min-width: 1280px){
.page-wrapper__container{
    padding-top:0
}
.widget-city-block{
    display:none
}
.header{
    display:block;
    background-color:#fff;
    width:100%
}
.header__container{
    display:flex;
    justify-content:flex-start;
    margin-left:auto;
    margin-right:auto;
    box-sizing:border-box;
    padding-top:18px;
    min-height:127px;
    max-width:1256px
}
.header__logo-link{
    border-bottom:none
}
.header__logo-link:hover{
    border-bottom:none
}
.header__logo-link--disabled{
    pointer-events:none
}
.header__logo-icon{
    width:230px
}
.header__wrapper{
    flex-grow:1;
    margin-left:67px
}
.header__line{
    display:flex;
    justify-content:space-between;
    align-items:center
}
.header__line--first{
    align-items:baseline
}
.header__line--second{
    margin-top:20px
}
.header__line--nav-links{
    align-items:flex-start
}
.header__nav-link{
    font-size:12px;
    color:#373737;
    text-decoration:none;
    border-bottom:none
}
.header__nav-link:visited{
    color:#373737
}
.header__nav-link:hover{
    border-bottom:none;
    color:#00aafa;
    opacity:1
}
.header__nav-link--phone{
    font-weight:bold;
    font-size:16px;
    line-height:1
}
.header__search{
    position:relative;
    display:flex;
    align-items:center
}
.header__search-submit{
    position:absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    width:24px;
    height:24px;
    border:none;
    background:transparent;
    padding:0
}
.header__search-submit:hover{
    background-color:initial
}
.header__search-input{
    font-size:12px;
    border:none;
    padding:8px 0;
    border-bottom:1px solid #373737;
    min-width:250px;
    outline:none;
    margin-left:15px;
    font-family:inherit
}
.header__search-input::placeholder{
    color:#373737
}
.header__search-input:focus::placeholder{
    color:transparent
}
.header__phone-icon{
    margin-right:8px
}
.header__phone-wrapper{
    display:flex;
    align-items:center
}
.header-nav{
    list-style:none;
    padding-left:0;
    margin-top:30px;
    margin-bottom:0
}
}