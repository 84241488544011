.search-input[data-v-7021275a]{
  position:relative
}
.search-input__menu[data-v-7021275a]{
  list-style-type:none;
  padding:0;
  margin:0;
  top:100%;
  left:0;
  width:100%;
  position:absolute;
  z-index:1;
  border:1px solid #e1e1e1;
  border-top:0;
  max-height:20vh;
  overflow-y:auto;
  background-color:#fff
}
.search-input__reset[data-v-7021275a]{
  position:absolute;
  top:50%;
  right:11px;
  cursor:pointer;
  transform:translateY(-50%)
}
.search-input__item[data-v-7021275a]{
  background-color:#fff;
  padding:5px;
  font-family:sans-serif;
  font-size:13px
}
.search-input__item[data-v-7021275a]::before{
  display:none
}
.search-input__item[data-v-7021275a]:hover{
  background-color:#00a9fa;
  color:#fff
}