.popup-mask[data-v-5c350429]{
  position:fixed;
  z-index:10001;
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color:rgba(0,0,0,.5);
  display:table;
  transition:opacity .3s ease
}
.popup-wrapper[data-v-5c350429]{
  display:table-cell;
  vertical-align:middle;
  bottom:0;
  position:fixed;
  width:100%;
  max-height:100%
}
.popup-container[data-v-5c350429]{
  position:relative;
  width:100%;
  margin:0px auto;
  background-color:#fff;
  transition:all .3s ease;
  box-sizing:border-box;
  border-radius:5px 5px 0 0;
  padding:24px
}
.popup-container .vue-popup-close[data-v-5c350429]{
  position:absolute;
  top:14px;
  right:14px;
  width:40px;
  height:40px;
  padding:0;
  background-image:url(/var/resources/static/images/closeaf0c.svg);
  background-repeat:no-repeat;
  background-size:14px 14px;
  border:none;
  background-color:transparent;
  cursor:pointer;
  background-position:center
}
@media (min-width: 640px){
.popup-container .vue-popup-close[data-v-5c350429]{
    top:27px;
    right:27px
}
}
.popup-container .vue-popup-close[data-v-5c350429]:focus{
  outline:none
}
.popup-body[data-v-5c350429]{
  text-align:center
}
.popup-default-button[data-v-5c350429]{
  float:right
}
.popup-enter[data-v-5c350429]{
  opacity:0
}
.popup-leave-active[data-v-5c350429]{
  opacity:0
}
.popup-enter .popup-container[data-v-5c350429],.popup-leave-active .popup-container[data-v-5c350429]{
  transform:scale(1.1)
}
.slide-enter-active .popup-container[data-v-5c350429],.slide-leave-active .popup-container[data-v-5c350429]{
  transition-duration:.3s
}
.slide-enter-to .popup-container[data-v-5c350429],.slide-leave .popup-container[data-v-5c350429]{
  transform:translateY(0);
  opacity:1
}
.slide-enter-to[data-v-5c350429],.slide-leave[data-v-5c350429]{
  opacity:1
}
.slide-enter[data-v-5c350429],.slide-leave-to[data-v-5c350429]{
  opacity:0
}
.slide-enter .popup-container[data-v-5c350429],.slide-leave-to .popup-container[data-v-5c350429]{
  transform:translateY(100%);
  opacity:0
}