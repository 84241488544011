.request-callback-popup__agreement-text[data-v-a9391da2]{
  font-size:11px;
  line-height:1.4;
  display:block;
  color:silver;
  text-transform:none;
  white-space:normal
}
.request-callback-popup__agreement-text a[data-v-a9391da2]{
  color:#00aafa;
  border-bottom:1px solid #d8d8d8;
  text-decoration:none
}