.c-profile-placeholder[data-v-6c84011b]{
  background-color:#f1f1f1;
  border-radius:5px;
  width:108px;
  height:21px
}
.c-location-placeholder[data-v-6c84011b]{
  background-color:#f1f1f1;
  border-radius:5px;
  width:150px;
  height:21px
}
.header__line--first[data-v-6c84011b]{
  align-items:center
}
.header__line-container[data-v-6c84011b]{
  max-width:1256px;
  margin:0 auto
}