@media(min-width: 1280px){
.header-nav__icon{
    margin-right:8px
}
.header-nav__inner-list{
    list-style:none;
    z-index:100;
    position:absolute;
    top:calc(100% + 6px);
    left:0;
    background-color:#fff;
    padding:15px 25px 15px 0;
    box-shadow:0px 0px 9px rgba(0,0,0,.25);
    min-width:-webkit-max-content;
    min-width:max-content
}
.header-nav__inner-list ul{
    list-style:none
}
.header-nav__inner-item::before{
    content:none
}
.header-nav__item{
    position:relative;
    padding-bottom:20px;
    padding-left:0;
    border-bottom:6px solid transparent
}
.header-nav__item::before{
    content:none
}
.header-nav__item:hover{
    border-color:#ee4981
}
.header-nav__item:hover>.header-nav__inner-list{
    display:flex
}
.header-nav__item .header-nav__inner-item-title{
    margin:0;
    margin-bottom:17px;
    font-size:17px;
    line-height:19px;
    font-weight:700
}
.header-nav__item .header-nav__inner-item-title--upper{
    text-transform:uppercase
}
.header-nav__item .header-nav__inner-list{
    padding:56px 0 47px 12px
}
.header-nav__item--glasses:hover>.header-nav__inner-list,.header-nav__item--frame:hover>.header-nav__inner-list{
    display:grid
}
.header-nav__item--glasses .header-nav__inner-list,.header-nav__item--frame .header-nav__inner-list{
    display:grid;
    grid-template-columns:196px 262px 269px 196px;
    grid-template-rows:194px auto;
    grid-template-areas:"gender material popular last" "shape lens more last" "shape lens more last"
}
.header-nav__item--glasses .header-nav__inner-item,.header-nav__item--frame .header-nav__inner-item{
    padding-left:20px
}
.header-nav__item--glasses .header-nav__inner-item-link,.header-nav__item--frame .header-nav__inner-item-link{
    font-size:18px;
    line-height:27.8px;
    white-space:nowrap
}
.header-nav__item--glasses .header-nav__inner-item-see-all,.header-nav__item--frame .header-nav__inner-item-see-all{
    font-size:15px;
    line-height:19px;
    color:#00aafa !important;
    border-bottom:1px dotted #00aafa;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display:none
}
.header-nav__item--glasses .header-nav__inner-item-see-all:hover,.header-nav__item--frame .header-nav__inner-item-see-all:hover{
    opacity:.7
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(1),.header-nav__item--frame .header-nav__inner-item:nth-child(1){
    grid-area:gender
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(2),.header-nav__item--frame .header-nav__inner-item:nth-child(2){
    grid-area:shape
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(3),.header-nav__item--frame .header-nav__inner-item:nth-child(3){
    grid-area:material
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(4),.header-nav__item--frame .header-nav__inner-item:nth-child(4){
    grid-area:lens
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(5),.header-nav__item--frame .header-nav__inner-item:nth-child(5){
    grid-area:popular
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(6),.header-nav__item--frame .header-nav__inner-item:nth-child(6){
    grid-area:more
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(7),.header-nav__item--frame .header-nav__inner-item:nth-child(7){
    grid-area:last
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(7) .header-nav__inner-item-see-all,.header-nav__item--frame .header-nav__inner-item:nth-child(7) .header-nav__inner-item-see-all{
    display:block;
    font-size:18px;
    margin-top:5px
}
.header-nav__item--glasses .header-nav__inner-item:nth-child(n+8),.header-nav__item--frame .header-nav__inner-item:nth-child(n+8){
    display:none
}
.header-nav__item--glasses .header-nav__inner-list{
    left:-205px
}
.header-nav__item--frame .header-nav__inner-list{
    left:-443px
}
.header-nav__item--lenses:hover>.header-nav__inner-list{
    display:grid
}
.header-nav__item--lenses .header-nav__inner-list{
    display:grid;
    grid-template-columns:262px 262px;
    grid-template-rows:194px auto;
    grid-template-areas:"frequency popular" "type all-brands"
}
.header-nav__item--lenses .header-nav__inner-item{
    padding-left:20px
}
.header-nav__item--lenses .header-nav__inner-item-title{
    white-space:nowrap
}
.header-nav__item--lenses .header-nav__inner-item-link{
    font-size:18px;
    line-height:27.8px;
    white-space:nowrap
}
.header-nav__item--lenses .header-nav__inner-item-see-all{
    font-size:15px;
    line-height:19px;
    color:#00aafa !important;
    border-bottom:1px dotted #00aafa;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display:none
}
.header-nav__item--lenses .header-nav__inner-item-see-all:hover{
    opacity:.7
}
.header-nav__item--lenses .header-nav__inner-item:nth-child(1){
    grid-area:frequency
}
.header-nav__item--lenses .header-nav__inner-item:nth-child(2){
    grid-area:type
}
.header-nav__item--lenses .header-nav__inner-item:nth-child(3){
    grid-area:popular
}
.header-nav__item--lenses .header-nav__inner-item:nth-child(4){
    grid-area:all-brands
}
.header-nav__item--lenses .header-nav__inner-item:nth-child(4) .header-nav__inner-item-see-all{
    display:block;
    font-size:18px;
    margin-top:5px
}
.header-nav__item .header-nav__inner-list{
    display:none
}
.header-nav__inner-item-see-all{
    display:none
}
.header-nav__inner-item-links{
    display:flex;
    flex-direction:column;
    padding-right:25px;
    padding-left:25px
}
.header-nav__inner-item-title{
    font-size:17px;
    line-height:19px;
    color:#00aafa;
    margin-bottom:17px;
    padding-left:25px;
    min-height:19px
}
.header-nav__inner-item-link{
    font-size:18px;
    line-height:27.8px;
    text-decoration:none;
    color:#373737;
    border-bottom:none
}
.header-nav__inner-item-link:visited{
    color:#373737
}
.header-nav__inner-item-link:hover{
    color:#ee4981;
    border-bottom:none
}
.header-nav__link{
    font-size:12px;
    line-height:18px;
    color:#373737;
    text-decoration:none;
    text-transform:uppercase;
    font-weight:600;
    display:flex;
    border-bottom:none;
    align-items:center
}
.header-nav__link:visited{
    color:#373737
}
.header-nav__link:hover{
    color:#ee4981;
    border-bottom:none
}
.header-nav__link-img{
    margin-right:6px;
    width:26px;
    height:26px;
    object-fit:contain
}
}