.c-checkbox[data-v-47e706fe]{
  display:flex;
  align-items:center
}
.c-checkbox.error[data-v-47e706fe]{
  background-color:#fad6d6
}
.c-checkbox__body[data-v-47e706fe]{
  box-sizing:border-box;
  border:1px solid #e5e7eb;
  margin-right:15px;
  height:14px;
  width:14px;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color:#fff
}
.c-checkbox__mark[data-v-47e706fe]{
  object-fit:contain
}