@keyframes slideInY-data-v-c13ab1ea{
0%{
    transform:translateY(100%)
}
100%{
    transform:translateY(0)
}
}
.c-button-placeholder[data-v-c13ab1ea]{
  background-color:#f1f1f1;
  border-radius:5px;
  width:50px;
  height:50px
}
.tap-bar-menu[data-v-c13ab1ea]{
  position:fixed;
  height:calc(100% - 271px);
  width:100%;
  bottom:65px;
  left:0;
  background:#fff;
  display:flex;
  flex-direction:column;
  z-index:6001;
  transition:transform .3s;
  transform:translateY(100%);
  overflow:auto;
  overscroll-behavior:none
}
.tap-bar-menu__overlay[data-v-c13ab1ea]{
  display:none;
  position:fixed;
  box-sizing:border-box;
  z-index:6001;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow:hidden
}
.tap-bar-menu__overlay.open[data-v-c13ab1ea]{
  display:block
}
.tap-bar-menu__overlay.open .tap-bar-menu[data-v-c13ab1ea]{
  animation:slideInY-data-v-c13ab1ea .3s forwards
}
.tap-bar-menu__link[data-v-c13ab1ea]{
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex:1;
  background-color:#f5f5f5;
  min-height:80px;
  padding-left:24px;
  color:#000;
  font-size:16px;
  text-transform:uppercase;
  text-decoration:none;
  border-bottom:none;
  margin:0 8px 8px;
  border-radius:5px
}
.tap-bar-menu__link[data-v-c13ab1ea]:visited{
  color:#000
}
.tap-bar-menu__link[data-v-c13ab1ea]:first-child{
  margin-top:8px
}
.tap-bar-menu-img[data-v-c13ab1ea]{
  height:100%;
  object-fit:cover;
  object-position:left
}
.tap-bar-menu-text[data-v-c13ab1ea]{
  text-transform:none
}
.tap-bar[data-v-c13ab1ea]{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  height:66px;
  padding:8px 0;
  background:#fff;
  display:flex;
  align-items:center;
  justify-content:space-around;
  z-index:999;
  border-top:1px solid #000;
  box-sizing:border-box
}
.tap-bar__nav-link-item[data-v-c13ab1ea]{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  border-bottom:none;
  height:100%;
  cursor:pointer;
  position:relative;
  text-decoration:none
}
.tap-bar__nav-link-item--disabled[data-v-c13ab1ea]{
  pointer-events:none
}
.tap-bar__nav-link-item[data-v-c13ab1ea]:active{
  background-color:#00aafa
}
.tap-bar__nav-link-item:active .tap-bar__nav-link-icon[data-v-c13ab1ea],.tap-bar__nav-link-item:active .tap-bar__nav-link-text[data-v-c13ab1ea]{
  color:#fff
}
.tap-bar__nav-link-counter[data-v-c13ab1ea]{
  position:absolute;
  display:none;
  flex-wrap:wrap;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  width:auto;
  min-width:14px;
  height:14px;
  font-size:9px;
  color:#fff;
  border-radius:6.95px 6.95px 6.95px 0;
  background-color:#00aafa;
  top:-6px;
  right:initial;
  left:26px;
  padding:0 2px
}
.tap-bar__nav-link-counter--active[data-v-c13ab1ea]{
  display:flex;
  background-color:#00aafa
}
.tap-bar__nav-link-text[data-v-c13ab1ea]{
  margin-bottom:0;
  font-size:9px;
  line-height:1;
  margin-top:5px;
  color:#373737;
  pointer-events:none
}
.tap-bar__nav-link-text--name[data-v-c13ab1ea]{
  text-transform:capitalize
}
.tap-bar__nav-link-icon[data-v-c13ab1ea]{
  max-width:28px;
  max-height:28px;
  color:#373737;
  pointer-events:none
}
@media print{
.tap-bar[data-v-c13ab1ea]{
    display:none
}
}
@media(min-width: 1280px){
.tap-bar[data-v-c13ab1ea]{
    display:none
}
.tap-bar-menu[data-v-c13ab1ea]{
    display:none
}
}