.mobile-header__column_bottom{
  justify-content:space-between;
  margin:12px 24px
}
.mobile-header__left{
  display:flex;
  align-items:center;
  grid-gap:14px;
  gap:14px;
  width:calc(100% - 170px)
}
@media(min-width: 640px){
.mobile-header__left{
    align-items:flex-start;
    grid-gap:34px;
    gap:34px;
    width:auto
}
}
.mobile-header__link-placeholder{
  background-color:#0087c8;
  border-radius:5px;
  width:75px;
  height:21px
}
.mobile-header{
  position:relative;
  display:none;
  background-color:#00aafa;
  padding:15px 0 0
}
.mobile-header--main{
  display:block
}
.mobile-header__logo{
  width:117px;
  height:auto
}
.mobile-header__logo-link{
  border-bottom:none
}
.mobile-header__logo-link--disabled{
  pointer-events:none
}
.mobile-header__column{
  display:flex;
  align-items:center;
  grid-gap:2px;
  gap:2px
}
@media(min-width: 640px){
.mobile-header__column{
    grid-gap:8px;
    gap:8px
}
}
.mobile-header__column--top{
  justify-content:space-between;
  position:relative;
  align-items:flex-end;
  margin:0 24px
}
.mobile-header__column--search{
  padding:8px 16px;
  position:relative;
  background-color:#fff
}
.mobile-header__column--link,.mobile-header__column--links{
  white-space:nowrap;
  justify-content:space-between
}
.mobile-header__call-us{
  width:34px;
  cursor:pointer
}
.mobile-header__search-input{
  font-size:16px;
  line-height:37px;
  border-radius:8px;
  border:none;
  padding-left:48px;
  width:100%;
  border:1px solid #000;
  font-family:inherit;
  box-sizing:border-box
}
.mobile-header__search-input::placeholder{
  color:#373737
}
.mobile-header__search-input:focus::placeholder{
  color:transparent
}
.mobile-header__search-icon{
  width:24px;
  height:24px;
  position:absolute;
  left:24px;
  top:50%;
  transform:translateY(-50%)
}
.mobile-header__search-submit{
  width:24px;
  height:24px;
  position:absolute;
  left:24px;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  background:transparent;
  border:none !important;
  cursor:pointer
}
.mobile-header__search-submit:hover{
  background:transparent
}
.mobile-header__link{
  color:#fff;
  text-decoration:none;
  font-size:11px;
  border-bottom:none;
  display:flex;
  align-items:center;
  min-width:0
}
.mobile-header__link:visited,.mobile-header__link:hover{
  color:#fff
}
.mobile-header__link--promo{
  flex-shrink:0
}
.mobile-header__geo{
  width:calc(100% - 54px)
}
.mobile-header__geo .mobile-header__link{
  justify-content:flex-start
}
.mobile-header__link-text--geo{
  text-overflow:ellipsis;
  overflow:hidden;
  font-size:13px;
  max-width:calc(100% - 37px)
}
@media(min-width: 500px){
.mobile-header__link-text--geo{
    max-width:none
}
}
@media(min-width: 640px){
.mobile-header__link-text--geo{
    font-size:20px
}
}
.mobile-header__icon{
  flex-shrink:0;
  width:12px;
  height:15px
}
@media(min-width: 640px){
.mobile-header__icon{
    width:18px;
    height:24px
}
}
.mobile-header__icon--geo,.mobile-header__icon--promo{
  margin-right:4px
}
.mobile-header__icon--arrow{
  margin-left:4px;
  width:17px;
  height:17px
}
@media(min-width: 640px){
.mobile-header__icon--arrow{
    width:27px;
    height:27px
}
}
@media(min-width: 1280px){
.mobile-header{
    display:none
}
.mobile-header__column_bottom{
    margin:22px 16px 8px
}
}