.mobile-fixed-header-wrapper{
  top:0;
  left:0;
  position:relative;
  width:100%;
  z-index:1001
}
.mobile-fixed-header{
  z-index:100;
  width:100%;
  background-color:#00aafa;
  display:flex;
  align-items:center;
  padding:9px 12px 10px 16px;
  box-sizing:border-box;
  overflow:hidden;
  transition:opacity .3s
}
.mobile-fixed-header__logo-link{
  margin-left:auto;
  margin-right:auto
}
.mobile-fixed-header--show{
  height:auto;
  opacity:1
}
.mobile-fixed-header--show .mobile-fixed-header{
  padding:9px 12px 10px 16px
}
@media(min-width: 480px){
.mobile-fixed-header{
    padding:9px 26px 10px 24px
}
}
.mobile-fixed-header .mobile-menu__button{
  height:20px;
  width:23px;
  position:relative;
  cursor:pointer;
  display:block;
  border-bottom:0
}
.mobile-fixed-header .mobile-menu__button:before,.mobile-fixed-header .mobile-menu__button:after,.mobile-fixed-header .mobile-menu__button .middle-line{
  content:"";
  width:20px;
  height:3px;
  display:block;
  position:absolute;
  left:0;
  background-color:#fff;
  transition:all .3s;
  border-radius:5px;
  transform-origin:left
}
.mobile-fixed-header .mobile-menu__button:before{
  top:0
}
.mobile-fixed-header .mobile-menu__button:after{
  bottom:0
}
.mobile-fixed-header .mobile-menu__button .middle-line{
  top:50%;
  transform:translateY(-50%)
}
.mobile-fixed-header .acuvue-link{
  position:relative;
  right:0;
  display:flex;
  align-items:center;
  margin-right:25px
}
.mobile-fixed-header__search{
  position:relative;
  padding-left:40px;
  margin-right:17px;
  flex-grow:1;
  max-width:420px
}
@media(min-width: 375px){
.mobile-fixed-header__search{
    margin-left:15px;
    padding-left:54px;
    display:flex
}
}
.mobile-fixed-header__search-input{
  font-size:16px;
  line-height:28px;
  border-radius:0;
  border:none;
  border-bottom:1px solid #fff;
  background-color:transparent;
  color:#fff;
  padding:0;
  width:100%;
  outline:none;
  font-family:inherit
}
.mobile-fixed-header__search-input::placeholder{
  color:#fff
}
.mobile-fixed-header__search-input:focus::placeholder{
  color:transparent
}
.mobile-fixed-header__search-icon{
  width:24px;
  height:24px;
  position:absolute;
  top:50%;
  left:7px;
  transform:translateY(-50%);
  color:#fff
}
@media(min-width: 375px){
.mobile-fixed-header__search-icon{
    left:15px
}
}
.mobile-fixed-header__search-submit{
  width:24px;
  height:24px;
  position:absolute;
  left:7px;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  background:transparent;
  border:none !important;
  cursor:pointer
}
@media(min-width: 375px){
.mobile-fixed-header__search-submit{
    left:15px
}
}
.mobile-fixed-header__search-submit:hover{
  background:transparent
}
.mobile-fixed-header__geo-link{
  color:#fff;
  text-decoration:none;
  font-size:12px;
  border-bottom:none;
  display:flex;
  align-items:center;
  min-width:0;
  flex-shrink:0;
  margin-right:20px;
  margin-left:auto;
  white-space:nowrap;
  max-width:80px
}
.mobile-fixed-header__geo-link:visited,.mobile-fixed-header__geo-link:hover{
  color:#fff
}
.mobile-fixed-header__geo-link-text{
  text-overflow:ellipsis;
  overflow:hidden
}
.mobile-fixed-header__geo-link-icon{
  flex-shrink:0;
  margin-right:9px
}
.mobile-fixed-header__call-us{
  flex-shrink:0;
  width:26px
}
.mobile-fixed-header .acuvue-bonus .acuvue-bonus__link{
  margin-right:25px
}
.mobile-fixed-header .acuvue-bonus .acuvue-bonus__link svg{
  width:37px;
  height:37px
}
@media(min-width: 480px){
.mobile-fixed-header__geo-link{
    max-width:none
}
}
@media(min-width: 1280px){
.mobile-fixed-header{
    display:none
}
}