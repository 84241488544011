@keyframes zIndex{
0%{
    z-index:10000
}
100%{
    transform:translateX(0)
}
}
@keyframes slideIn{
0%{
    transform:translateX(-100%)
}
100%{
    transform:translateX(0)
}
}
.mobile-menu{
  position:absolute;
  top:.5px;
  left:0;
  right:0;
  bottom:.5px;
  overflow-x:hidden;
  overflow-y:auto;
  z-index:-1
}
.mobile-menu.close{
  animation:zIndex .25s
}
.mobile-menu.open{
  z-index:10000000000
}
.mobile-menu__overlay{
  position:fixed;
  top:0;
  left:0;
  height:100vh;
  width:100%;
  background-color:rgba(0,0,0,.6);
  display:none;
  z-index:1002
}
.mobile-menu__content{
  position:fixed;
  top:0;
  left:0;
  width:calc(100% - 75px);
  height:100%;
  background-color:#fff;
  overflow:auto;
  transform:translateX(-100%);
  transition:.3s;
  z-index:1003;
  overscroll-behavior:none
}
.mobile-menu__content.hidden{
  overflow:hidden
}
.mobile-menu__close{
  position:absolute;
  top:10px;
  right:30px;
  z-index:1;
  cursor:pointer
}
.mobile-menu__button{
  height:26px;
  width:30px;
  position:relative;
  cursor:pointer;
  display:block;
  border-bottom:0
}
.mobile-menu__button:before,.mobile-menu__button:after,.mobile-menu__button .middle-line{
  content:"";
  width:30px;
  height:4px;
  display:block;
  position:absolute;
  left:0;
  background-color:#fff;
  transition:all .3s;
  border-radius:5px;
  transform-origin:left;
  pointer-events:none
}
.mobile-menu__button:before{
  top:0
}
.mobile-menu__button:after{
  bottom:0
}
.mobile-menu__button .middle-line{
  top:50%;
  transform:translateY(-50%)
}
.mobile-menu__item{
  border-bottom:1px solid rgba(230,230,230,.61);
  min-height:40px;
  display:flex;
  align-items:center;
  box-sizing:border-box
}
.mobile-menu__item.open{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background-color:#fff;
  z-index:5
}
.mobile-menu__item.open .mobile-menu__item-link{
  margin-left:10px
}
.mobile-menu__item.open .mobile-menu__icon{
  order:-1;
  transform:scaleX(-1);
  margin-left:0;
  margin-right:10px;
  width:auto
}
.mobile-menu__item.open .mobile-menu__icon--arrow{
  padding-right:20px;
  width:20px;
  box-sizing:content-box;
  margin-right:0
}
.mobile-menu__item-link{
  flex:1;
  text-decoration:none;
  font-size:14px;
  font-weight:600;
  color:#000;
  display:flex;
  align-items:center;
  border-bottom:none
}
.mobile-menu__item-link:visited{
  color:#000
}
.mobile-menu__item-link--uppercase{
  text-transform:uppercase
}
.mobile-menu__item-link--no-img{
  padding-left:50px
}
.mobile-menu__item-link-img{
  margin:0 15px;
  width:20px;
  height:22px;
  flex:0 0 20px;
  opacity:.6
}
.mobile-menu__item-link-text--no-category{
  padding-right:12px
}
.mobile-menu__divider{
  background-color:#e2e2e2;
  height:4px;
  border-bottom:1px solid #f3f3f3;
  box-sizing:border-box
}
.mobile-menu__icon{
  width:50px;
  pointer-events:none;
  flex-shrink:0
}
.mobile-menu__submenu{
  position:fixed;
  display:none;
  top:40px;
  left:0px;
  background-color:#fff;
  width:100%;
  height:calc(100% - 40px);
  overscroll-behavior:none
}
.mobile-menu__submenu.open{
  display:block;
  animation:slideIn .25s;
  overflow:auto;
  z-index:5
}
.mobile-menu__submenu-title{
  display:block;
  font-size:14px;
  line-height:30px;
  padding-top:20px;
  padding-left:40px;
  border-bottom:1px solid #f3f3f3
}
.mobile-menu__submenu-link{
  display:flex;
  align-items:center;
  text-decoration:none;
  color:#000;
  font-weight:600;
  min-height:30px;
  border-bottom:1px solid #f3f3f3;
  padding-left:40px;
  font-size:14px;
  box-sizing:border-box
}
.mobile-menu__submenu-link:visited{
  color:#000
}
.mobile-menu__submenu-link:hover{
  border-bottom:1px solid #f3f3f3
}
.mobile-menu__submenu-see-all{
  display:flex;
  align-items:center;
  text-decoration:none;
  color:#00aafa;
  font-weight:600;
  min-height:30px;
  border-bottom:1px solid #f3f3f3;
  padding-left:40px;
  font-size:14px;
  box-sizing:border-box;
  cursor:pointer;
  display:none
}
.mobile-menu__submenu-see-all:hover{
  opacity:.7;
  color:#00aafa;
  border-bottom:1px solid #f3f3f3
}
.mobile-menu__submenu--glasses .mobile-menu__submenu-see-all,.mobile-menu__submenu--frame .mobile-menu__submenu-see-all,.mobile-menu__submenu--lenses .mobile-menu__submenu-see-all{
  display:flex
}
.mobile-menu.open .mobile-menu__overlay{
  display:block
}
.mobile-menu.open .mobile-menu__content{
  transform:translateX(0)
}