@media(min-width: 1280px){
.header__nav-link--city[data-v-3a0551e0]{
    display:flex;
    flex-direction:row;
    align-items:baseline;
    font-weight:600;
    line-height:12px;
    cursor:pointer
}
.header__nav-link-icon--map-pin[data-v-3a0551e0]{
    margin-right:12px;
    line-height:12px
}
.header__nav-link-icon--arrow[data-v-3a0551e0]{
    margin-left:9px;
    line-height:12;
    padding-bottom:1px
}
}