.doctor-appointment__icon[data-v-742aa19b]{
  position:relative;
  width:27px;
  height:27px
}
@media(min-width: 640px){
.doctor-appointment__icon[data-v-742aa19b]{
    width:32px;
    height:32px
}
}
@media(min-width: 1280px){
.doctor-appointment__icon[data-v-742aa19b]{
    width:24px;
    height:24px
}
}
.doctor-appointment__icon-path[data-v-742aa19b]{
  stroke:#ee4981
}
@media(min-width: 1280px){
.doctor-appointment__icon-path[data-v-742aa19b]{
    stroke:#00aafa
}
}
.doctor-appointment__icon-circle[data-v-742aa19b]{
  fill:#ee4981
}
@media(min-width: 1280px){
.doctor-appointment__icon-circle[data-v-742aa19b]{
    fill:#00aafa
}
}
.doctor-appointment__text[data-v-742aa19b]{
  position:relative;
  font-size:16px;
  color:#fff;
  text-decoration:none;
  border-bottom:none;
  text-wrap:nowrap;
  cursor:pointer
}
.doctor-appointment__text[data-v-742aa19b]:hover{
  opacity:.7
}
@media(min-width: 640px){
.doctor-appointment__text[data-v-742aa19b]{
    font-size:18px
}
}
@media(min-width: 1280px){
.doctor-appointment__text[data-v-742aa19b]{
    font-size:12px
}
}
.header__medical-record[data-v-742aa19b]{
  display:block;
  overflow:hidden;
  border-radius:25px;
  width:100%;
  height:100%;
  opacity:.9999
}
.header__medical-record-wrapper[data-v-742aa19b]{
  width:170px;
  height:32px
}
@media(min-width: 640px){
.header__medical-record-wrapper[data-v-742aa19b]{
    width:191px;
    height:34px
}
}
@media(min-width: 1280px){
.header__medical-record-wrapper[data-v-742aa19b]{
    width:140px;
    height:26px
}
}
.header__medical-record-video[data-v-742aa19b]{
  position:relative;
  top:-2px;
  left:-3px;
  width:175px;
  max-width:none
}
@media(min-width: 640px){
.header__medical-record-video[data-v-742aa19b]{
    top:-2px;
    left:-3px;
    width:199px
}
}
@media(min-width: 1280px){
.header__medical-record-video[data-v-742aa19b]{
    top:-1px;
    left:-2px;
    width:143px
}
}