@media(min-width: 1280px){
.mobile-banner{
    display:none
}
}
.mobile-fixed-header__geo-link-wrapper{
  margin-left:auto;
  cursor:pointer
}
.mobile-fixed-header__geo-link-client-only-wrapper{
  margin-left:auto
}
.mobile-fixed-header__geo-link-placeholder{
  background-color:#0087c8;
  border-radius:5px;
  width:100px;
  height:21px;
  margin-right:20px
}