.c-select-menu[data-v-3876ee79]{
  position:relative
}
.c-select-menu__arrow[data-v-3876ee79]{
  object-fit:contain;
  position:absolute;
  top:50%;
  right:11px;
  transform:translateY(-50%)
}
.c-select-menu__selected[data-v-3876ee79]{
  display:block;
  width:100%;
  border:1px solid #e1e1e1;
  background-color:#fff;
  padding:0 11px;
  box-sizing:border-box;
  line-height:28px;
  font-size:13px;
  height:auto;
  box-shadow:none;
  outline:none;
  -webkit-user-select:none;
          user-select:none;
  cursor:pointer
}
.c-select-menu__list[data-v-3876ee79]{
  list-style-type:none;
  padding:0;
  margin:0;
  top:100%;
  left:0;
  width:100%;
  position:absolute;
  z-index:1;
  border:1px solid #e1e1e1;
  border-top:0;
  max-height:20vh;
  overflow-y:auto;
  background-color:#fff
}
.c-select-menu__item[data-v-3876ee79]{
  background-color:#fff;
  margin-bottom:0;
  padding:5px;
  font-size:13px;
  cursor:pointer;
  line-height:1.5
}
.c-select-menu__item[data-v-3876ee79]:before{
  display:none
}
.c-select-menu__item[data-v-3876ee79]:hover{
  background-color:#00a9fa;
  color:#fff
}