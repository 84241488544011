.myacuvue-popup[data-v-43055f82]{
  position:fixed;
  opacity:0;
  display:flex;
  visibility:hidden;
  top:0;
  left:0;
  width:100%;
  height:100%;
  overflow:auto;
  background-color:rgba(0,0,0,.4);
  align-items:center;
  justify-content:center;
  transition:visibility .3s linear,opacity .3s linear;
  z-index:100
}
.myacuvue-popup a[data-v-43055f82]{
  color:#00aafa;
  border-bottom:1px solid #d8d8d8
}
.myacuvue-popup .popup-body[data-v-43055f82]{
  position:relative;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:40px 80px 50px;
  width:100%;
  max-width:495px;
  height:auto;
  min-height:260px;
  box-shadow:6px 4px 14px rgba(11,74,142,.15);
  background:#fff;
  border-radius:8px;
  margin:auto;
  box-sizing:border-box
}
.myacuvue-popup .popup-body .popup-button-close[data-v-43055f82]{
  position:absolute;
  top:15px;
  right:15px;
  width:20px;
  height:20px;
  cursor:pointer
}
.myacuvue-popup .popup-body .popup-button-close img[data-v-43055f82]{
  width:100%
}
.myacuvue-popup .popup-body .popup-header h4[data-v-43055f82]{
  font-size:24px;
  line-height:40px;
  font-weight:700;
  text-transform:uppercase;
  color:#373737;
  margin-bottom:30px
}
.myacuvue-popup .popup-body .popup-form-auth[data-v-43055f82]{
  display:flex
}
.myacuvue-popup .popup-body input[data-v-43055f82]{
  height:48px;
  padding:15px 25px;
  font-size:14px;
  line-height:18px;
  background:#f2f5f7;
  border-radius:40px;
  border:none;
  outline:none;
  box-sizing:border-box;
  width:100%
}
.myacuvue-popup .popup-body small[data-v-43055f82]{
  display:inline-block;
  margin-top:6px;
  padding-left:25px;
  font-size:14px;
  line-height:18px;
  color:#373737
}
.myacuvue-popup .popup-body small.red[data-v-43055f82]{
  color:#d9021c
}
.myacuvue-popup .popup-body .popup-input[data-v-43055f82]{
  margin-bottom:15px
}
.myacuvue-popup .popup-body .popup-input input[data-v-43055f82]{
  text-transform:uppercase;
  color:#373737
}
.myacuvue-popup .popup-body .popup-input input[data-v-43055f82]::placeholder{
  color:#868686
}
.myacuvue-popup .popup-body .popup-input input.invalid[data-v-43055f82]{
  border:1px solid #d9021c
}
.myacuvue-popup .popup-body .popup-form-check[data-v-43055f82]{
  display:flex;
  flex-direction:column;
  width:100%
}
.myacuvue-popup .popup-body .popup-form-check .popup-input[data-v-43055f82]{
  margin-bottom:15px
}
.myacuvue-popup .popup-body .popup-form-check .popup-input input[data-v-43055f82]{
  width:100%
}
.myacuvue-popup .popup-body .popup-form-check .popup-input label[data-v-43055f82]{
  text-transform:none
}
.myacuvue-popup .popup-body .popup-form-auth[data-v-43055f82]{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:space-between;
  width:100%
}
.myacuvue-popup .popup-body .popup-form-auth .popup-input[data-v-43055f82]{
  width:47%;
  margin-bottom:35px
}
.myacuvue-popup .popup-body .popup-form-auth .popup-input small[data-v-43055f82]{
  padding-left:0
}
.myacuvue-popup .popup-body .popup-form-auth .popup-button[data-v-43055f82]{
  width:47%
}
.myacuvue-popup .popup-body .popup-form-auth .popup-form-auth-repeat[data-v-43055f82]{
  width:100%
}
.myacuvue-popup .popup-body .popup-form-auth .popup-form-auth-repeat small[data-v-43055f82]{
  padding-left:25px;
  font-size:12px;
  line-height:14px;
  color:#c8c8c8
}
.myacuvue-popup .popup-body .popup-form-auth .popup-form-auth-repeat small span[data-v-43055f82]{
  color:#ee4981
}
.myacuvue-popup .popup-body .popup-form-auth .popup-form-auth-repeat .popup-button[data-v-43055f82]{
  width:100%;
  margin-bottom:35px
}
.myacuvue-popup .popup-body .popup-button[data-v-43055f82]{
  width:100%;
  color:#fff;
  background:#002f86;
  text-align:center;
  text-transform:none;
  border:1px solid #002f86;
  height:48px;
  padding:15px 25px;
  font-size:14px;
  line-height:18px;
  border-radius:40px;
  outline:none;
  box-sizing:border-box
}
.myacuvue-popup .popup-body .popup-button[data-v-43055f82]:hover{
  border:1px solid #002f86;
  color:#002f86;
  background:#fff
}
.myacuvue-popup .popup-body .popup-button[data-v-43055f82]:active{
  border:1px solid #002f86;
  color:#002f86;
  background:#fff;
  opacity:.45
}
.myacuvue-popup .popup-body .popup-button.disable[data-v-43055f82]{
  color:#5b6770;
  background:#d9dee1;
  pointer-events:none;
  border:1px solid #d9dee1
}
.myacuvue-popup .popup-body .disable[data-v-43055f82]{
  color:#5b6770;
  background:#d9dee1;
  pointer-events:none;
  border:1px solid #d9dee1
}
.myacuvue-popup .popup-body .popup-message[data-v-43055f82]{
  width:100%;
  max-width:290px;
  margin:0 auto
}
[dir="ltr"] .myacuvue-popup .popup-body .popup-message p[data-v-43055f82]{
  text-align:left
}
[dir="rtl"] .myacuvue-popup .popup-body .popup-message p[data-v-43055f82]{
  text-align:right
}
.myacuvue-popup .popup-body .popup-message p[data-v-43055f82]{
  display:inline;
  font-size:16px;
  line-height:20px;
  color:#373737
}
.myacuvue-popup .popup-body .popup-message p span[data-v-43055f82]{
  color:#002f86
}
.myacuvue-popup .popup-body .popup-message .popup-button[data-v-43055f82]{
  margin-top:15px
}
.myacuvue-popup .popup-body .hidden[data-v-43055f82]{
  display:none
}
.myacuvue-popup.open-popup[data-v-43055f82]{
  opacity:1;
  visibility:visible
}
@media screen and (max-width: 600px){
.myacuvue-popup .popup-body[data-v-43055f82]{
    padding:75px 10px 50px;
    max-width:305px
}
.myacuvue-popup .popup-body .popup-header[data-v-43055f82]{
    padding:0 20px
}
.myacuvue-popup .popup-body .popup-header h4[data-v-43055f82]{
    font-size:20px;
    line-height:24px;
    margin-bottom:25px
}
.myacuvue-popup .popup-body .popup-form-check .popup-input[data-v-43055f82]{
    margin-bottom:12px
}
.myacuvue-popup .popup-body .popup-form-check .popup-label[data-v-43055f82]{
    font-size:12px;
    line-height:16px
}
.myacuvue-popup .popup-body .popup-input small[data-v-43055f82]{
    font-size:12px;
    line-height:15px
}
.myacuvue-popup .popup-body .popup-form-auth .popup-form-auth-repeat small[data-v-43055f82]{
    padding-left:0
}
.myacuvue-popup .popup-body .popup-message p[data-v-43055f82]{
    font-size:14px
}
.myacuvue-popup .popup-body input[data-v-43055f82]{
    font-size:16px
}
}