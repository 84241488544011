.request-callback-wrapper[data-v-a0877472]{
  line-height:0
}
.c-request-callback[data-v-a0877472]{
  line-height:14px
}
.request-callback-popup__header[data-v-a0877472]{
  margin-bottom:20px
}
.request-callback-popup__title[data-v-a0877472]{
  font-size:26px;
  font-weight:700;
  line-height:32px;
  color:#000;
  text-transform:uppercase;
  margin-bottom:15px
}
.request-callback-popup__subtitle[data-v-a0877472]{
  font-size:18px;
  font-weight:700;
  line-height:22px;
  color:#362b36;
  text-transform:uppercase;
  margin:0
}
.request-callback-popup__block[data-v-a0877472]{
  position:relative;
  margin-bottom:20px
}
.request-callback-popup__block[data-v-a0877472]:last-child{
  margin-bottom:0
}
.request-callback-popup__block_time[data-v-a0877472]{
  margin-bottom:7px
}
.request-callback-popup__block_agreement[data-v-a0877472]{
  margin-top:10px;
  margin-bottom:0
}
.request-callback-popup-container[data-v-a0877472]  .popup-body{
  width:340px;
  overflow-y:visible
}