@keyframes lds-spinner-data-v-131b1e11{
0%{
    opacity:1
}
100%{
    opacity:0
}
}
.lds-spinner[data-v-131b1e11]{
  width:32px;
  height:32px;
  margin:0 auto
}
.lds-spinner div[data-v-131b1e11]{
  transform-origin:16px 16px;
  animation:lds-spinner-data-v-131b1e11 1.2s linear infinite
}
.lds-spinner div[data-v-131b1e11]:after{
  content:"";
  display:block;
  position:absolute;
  top:23px;
  left:15px;
  width:2.4px;
  height:9px;
  border-radius:2px;
  background:#9a9a9a
}
.lds-spinner div[data-v-131b1e11]:nth-child(1){
  transform:rotate(0deg);
  animation-delay:-1.1s
}
.lds-spinner div[data-v-131b1e11]:nth-child(2){
  transform:rotate(30deg);
  animation-delay:-1s
}
.lds-spinner div[data-v-131b1e11]:nth-child(3){
  transform:rotate(60deg);
  animation-delay:-0.9s
}
.lds-spinner div[data-v-131b1e11]:nth-child(4){
  transform:rotate(90deg);
  animation-delay:-0.8s
}
.lds-spinner div[data-v-131b1e11]:nth-child(5){
  transform:rotate(120deg);
  animation-delay:-0.7s
}
.lds-spinner div[data-v-131b1e11]:nth-child(6){
  transform:rotate(150deg);
  animation-delay:-0.6s
}
.lds-spinner div[data-v-131b1e11]:nth-child(7){
  transform:rotate(180deg);
  animation-delay:-0.5s
}
.lds-spinner div[data-v-131b1e11]:nth-child(8){
  transform:rotate(210deg);
  animation-delay:-0.4s
}
.lds-spinner div[data-v-131b1e11]:nth-child(9){
  transform:rotate(240deg);
  animation-delay:-0.3s
}
.lds-spinner div[data-v-131b1e11]:nth-child(10){
  transform:rotate(270deg);
  animation-delay:-0.2s
}
.lds-spinner div[data-v-131b1e11]:nth-child(11){
  transform:rotate(300deg);
  animation-delay:-0.1s
}
.lds-spinner div[data-v-131b1e11]:nth-child(12){
  transform:rotate(330deg);
  animation-delay:0s
}
.vue-pure-target html[data-v-131b1e11],.vue-pure-target body[data-v-131b1e11]{
  height:100%;
  font-size:100%
}
.vue-pure-target html[data-v-131b1e11]{
  line-height:1.5
}
.vue-pure-target body[data-v-131b1e11]{
  min-height:100%;
  background-color:#fff;
  font-size:12px
}
.vue-pure-target h1[data-v-131b1e11]{
  font-size:40px;
  line-height:48px;
  color:#000;
  margin-bottom:25px;
  text-transform:uppercase
}
.vue-pure-target h2[data-v-131b1e11]{
  font-size:30px;
  color:#000;
  margin-bottom:15px;
  line-height:48px;
  text-transform:uppercase
}
.vue-pure-target h3[data-v-131b1e11]{
  font-size:20px;
  line-height:30px;
  text-transform:uppercase
}
.vue-pure-target h4[data-v-131b1e11]{
  color:#00aafa;
  font-size:16px;
  line-height:48px;
  text-transform:uppercase;
  margin-bottom:5px
}
.vue-pure-target p[data-v-131b1e11]{
  line-height:2;
  color:#000;
  margin-bottom:1.5em
}
.vue-pure-target mark[data-v-131b1e11]{
  font-size:16px;
  line-height:28px;
  background-color:#fc48a2;
  color:#fff;
  text-align:left;
  padding-left:10px
}
.vue-pure-target small[data-v-131b1e11]{
  font-size:11px
}
.vue-pure-target blockquote[data-v-131b1e11]{
  border-top:1px solid #e5e5e5;
  border-bottom:1px solid #e5e5e5;
  position:relative;
  font-size:26px;
  line-height:40px;
  padding:55px 0;
  font-style:italic;
  text-align:center;
  margin:30px 0
}
.vue-pure-target blockquote[data-v-131b1e11]:before{
  width:71px;
  height:19px;
  content:"";
  position:absolute;
  top:-9px;
  left:50%;
  margin-left:-35px
}
.vue-pure-target thead[data-v-131b1e11]{
  background-color:#00aafa;
  font-size:14px;
  line-height:25px
}
.vue-pure-target td[data-v-131b1e11]{
  padding:0 5px
}
.vue-pure-target .icon[data-v-131b1e11]{
  display:inline-block
}
.vue-pure-target .bordered[data-v-131b1e11]{
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc
}
.vue-pure-target b[data-v-131b1e11],.vue-pure-target strong[data-v-131b1e11]{
  font-weight:bold
}
.vue-pure-target input[type=submit][data-v-131b1e11],.vue-pure-target button[data-v-131b1e11],.vue-pure-target a.button[data-v-131b1e11]{
  border:1px solid #00aafa;
  overflow:visible;
  white-space:nowrap;
  font-family:inherit;
  font-size:inherit;
  color:#fff;
  transition:color .2s ease,background-color .2s ease;
  -webkit-transition:color .2s ease,background-color .2s ease;
  -o-transition:color .2s ease,background-color .2s ease;
  -moz-transition:color .2s ease,background-color .2s ease;
  -ms-transition:color .2s ease,background-color .2s ease;
  outline:none
}
@media screen and (max-width: 640px){
.vue-pure-target html[data-v-131b1e11],.vue-pure-target body[data-v-131b1e11]{
    font-size:32.25%
}
}
.vue-pure-target h1[data-v-131b1e11],.vue-pure-target h2[data-v-131b1e11],.vue-pure-target h3[data-v-131b1e11]{
  text-transform:none
}
.vue-pure-target h1[data-v-131b1e11]{
  margin:0px 0px 0px 100px;
  padding:43px 0px 0px 12px;
  font-size:36px;
  color:#595b5c;
  line-height:36px
}
.vue-pure-target h2[data-v-131b1e11]{
  font-size:24px;
  margin:18px 0;
  line-height:36px
}
.vue-pure-target .page-wrapper[data-v-131b1e11]{
  margin:auto;
  min-height:100%
}
.vue-pure-target .page-main__wrapper[data-v-131b1e11]{
  width:100%;
  position:relative;
  margin-top:20px
}
.vue-pure-target .page-main__container[data-v-131b1e11]{
  margin:0 auto;
  padding:0;
  position:relative
}
.vue-pure-target .page-main__content[data-v-131b1e11]{
  width:100%;
  margin-left:0;
  float:none
}
.vue-pure-target .page-main__content h1[data-v-131b1e11],.vue-pure-target .page-main__content .h1[data-v-131b1e11]{
  padding:0;
  margin:0;
  font-size:32px;
  line-height:40px;
  font-weight:700;
  color:#000;
  margin-bottom:25px
}
.vue-pure-target .page-main__sidebar~*[data-v-131b1e11],.vue-pure-target .page-main__sidebar+.page-main__content[data-v-131b1e11]{
  width:72%;
  float:right;
  margin-right:1.6%
}
.vue-pure-target .product-main-header__heading h1[data-v-131b1e11]{
  text-transform:uppercase !important
}
.vue-pure-target a#main-content[data-v-131b1e11]{
  border-bottom:0 !important;
  display:block;
  height:0;
  width:100%
}
.vue-pure-target .page-main__wrapper.top-margin[data-v-131b1e11]{
  margin-top:0
}
.vue-pure-target .grecaptcha-badge[data-v-131b1e11]{
  z-index:1001;
  display:none !important
}
.vue-pure-target .itech-link[data-v-131b1e11]{
  border-bottom:0 !important;
  padding-left:30px;
  color:#9b9b9b;
  font-size:14px;
  display:inline-block;
  line-height:20px;
  vertical-align:middle
}
.vue-pure-target .mp-widget .mp-small-rating-widget-wrapper[data-v-131b1e11]{
  display:flex !important;
  align-items:center;
  flex-wrap:wrap
}
.vue-pure-target .mp-widget .mp-small-rating-widget-review-rating[data-v-131b1e11]{
  height:auto
}
.vue-pure-target .mp-widget .mp-small-rating-widget-rating-value[data-v-131b1e11]{
  color:#00aafa
}
.vue-pure-target .file__text[data-v-131b1e11]{
  margin-bottom:0
}
.vue-pure-target button.main-button[data-v-131b1e11],.vue-pure-target input.main-button[data-v-131b1e11]{
  line-height:1.15
}
.vue-pure-target button.main-button span[data-v-131b1e11],.vue-pure-target input.main-button span[data-v-131b1e11]{
  font-size:18px;
  line-height:inherit !important
}
.vue-pure-target .login-box h1[data-v-131b1e11],.vue-pure-target .login-box h2[data-v-131b1e11],.vue-pure-target .login-box h3[data-v-131b1e11]{
  color:#69a4c9;
  font-weight:normal
}
.vue-pure-target .title.blog-item-title a[data-v-131b1e11]{
  overflow-wrap:break-word
}
@media(min-width: 300px){
.vue-pure-target .page-main__wrapper#main[data-v-131b1e11]{
    display:flex;
    flex-direction:column
}
.vue-pure-target .page-main__container[data-v-131b1e11]{
    width:100%
}
}
@media(max-width: 300px){
.vue-pure-target .page-main__wrapper.top-margin[data-v-131b1e11]{
    margin-top:20px
}
}
@media(max-width: 640px){
.vue-pure-target .page-main__wrapper.top-margin[data-v-131b1e11]{
    margin-top:20px
}
}
@media(max-width: 1249px){
.vue-pure-target .page-wrapper[data-v-131b1e11]{
    width:100%
}
.vue-pure-target .page-main__wrapper.top-margin[data-v-131b1e11]{
    margin-top:65px
}
.vue-pure-target .product-main-header__heading h1[data-v-131b1e11]{
    font-size:3rem;
    line-height:1.3;
    margin:0 0 10px 0
}
.vue-pure-target .product-breadcrumbs[data-v-131b1e11]{
    order:-1;
    margin-bottom:20px;
    font-size:14px;
    color:#999
}
.vue-pure-target .mp-product-widget-content div[data-v-131b1e11]{
    font-size:16px
}
}
@media(min-width: 1280px){
.vue-pure-target .page-main__container[data-v-131b1e11]{
    max-width:1280px
}
}
.popup_open[data-v-131b1e11]{
  overflow:hidden
}
@media(min-width: 1280px){
.popup_open[data-v-131b1e11]{
    overflow:auto
}
}
.mp-icon-star[data-v-131b1e11]::before,.mp-icon-star-empty[data-v-131b1e11]::before{
  color:#00aafa !important
}
.ly-button-wrapper[data-v-131b1e11]{
  width:auto !important;
  height:auto !important;
  border:none !important
}
.contact-us-modal__title[data-v-131b1e11]{
  font-family:inherit;
  font-size:17px;
  line-height:22px;
  font-weight:bold;
  text-transform:none;
  margin-bottom:12px;
  margin-top:0;
  text-align:center
}
.contact-us-modal__text[data-v-131b1e11]{
  font-size:12px;
  line-height:16px;
  margin-top:0;
  margin-bottom:18px;
  text-align:center
}
.contact-us-modal__text--or[data-v-131b1e11]{
  font-weight:600;
  font-size:16px;
  line-height:18px
}
.contact-us-modal__links[data-v-131b1e11]{
  display:flex
}
.contact-us-modal__links--column[data-v-131b1e11]{
  flex-direction:column
}
.contact-us-modal__links--social[data-v-131b1e11]{
  max-width:136px;
  justify-content:space-between;
  max-width:136px;
  margin:0 auto;
  margin-bottom:15px
}
.contact-us-modal__link[data-v-131b1e11]{
  border-bottom:none;
  text-decoration:none;
  line-height:22px;
  line-height:1
}
.contact-us-modal__link[data-v-131b1e11]:hover{
  border-bottom:none;
  opacity:.7
}
.contact-us-modal__link--button[data-v-131b1e11]{
  color:#fff;
  background-color:#00aafa;
  border:1px solid #fff;
  border-radius:5px;
  height:60px;
  align-items:center;
  display:flex;
  text-align:center;
  justify-content:center;
  font-size:16px;
  line-height:22px;
  transition:.3s;
  cursor:pointer;
  margin-bottom:17px
}
.contact-us-modal__link--button[data-v-131b1e11]:last-child{
  margin-bottom:0
}
.contact-us-modal__link--button[data-v-131b1e11]:hover{
  border:1px solid #49a6db;
  color:#49a6db;
  background-color:#fff;
  opacity:1
}
.contact-us-modal__link--white[data-v-131b1e11]{
  color:#00aafa;
  background-color:#fff;
  border:1px solid #00aafa
}
.contact-us-modal__link--white[data-v-131b1e11]:hover{
  border:1px solid #fff;
  background-color:#49a6db;
  color:#fff
}