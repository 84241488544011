.city-selection-popup__default-cities[data-v-230b1657]{
  overflow-y:auto;
  max-height:35vh;
  height:100%
}
@media(max-width: 640px){
.city-selection-popup__default-cities[data-v-230b1657]{
    max-height:30vh
}
}
.city-selection-popup__default-city[data-v-230b1657]{
  cursor:pointer;
  font-size:14px;
  font-weight:normal;
  line-height:20px;
  border-bottom:1px solid #f3f3f3;
  padding:12px 24px;
  color:#373737;
  margin-bottom:0
}
.city-selection-popup__default-city[data-v-230b1657]:before{
  content:none
}
@media(max-width: 640px){
.city-selection-popup__default-city[data-v-230b1657]{
    padding-left:0;
    text-align:left
}
}
.city-selection-popup__title[data-v-230b1657]{
  font-weight:600;
  font-size:16px;
  line-height:18px;
  color:#373737;
  text-transform:none;
  text-align:center
}
.city-selection-popup__input-wrapper[data-v-230b1657]{
  margin:12px 0;
  width:100%;
  position:relative;
  color:#373737
}
.city-selection-popup__input[data-v-230b1657]{
  font-size:16px;
  border:1px solid #e2e2e2;
  box-sizing:border-box;
  border-radius:5px;
  padding:15px 15px 8px;
  width:100%;
  height:46px
}
.city-selection-popup__input[data-v-230b1657]:focus{
  outline-color:#00aafa
}
.city-selection-popup__placeholder[data-v-230b1657]{
  position:absolute;
  top:16px;
  left:17px;
  font-weight:normal;
  color:#373737;
  transition:.3s;
  pointer-events:none;
  text-transform:none;
  font-size:14px
}
.city-selection-popup__placeholder--active[data-v-230b1657]{
  transform:translate3d(0, -60%, 0);
  color:#00aafa
}
.city-selection-popup__closeBtn[data-v-230b1657]{
  position:absolute;
  width:20px;
  height:20px;
  right:19px;
  top:14px;
  cursor:pointer
}
.city-selection-popup__closeBtn[data-v-230b1657]::before,.city-selection-popup__closeBtn[data-v-230b1657]::after{
  content:"";
  width:3px;
  height:20px;
  background:#373737;
  position:absolute
}
.city-selection-popup__closeBtn[data-v-230b1657]::after{
  transform:rotate(45deg)
}
.city-selection-popup__closeBtn[data-v-230b1657]::before{
  transform:rotate(-45deg)
}
.city-selection-popup__input:focus+.city-selection-popup__placeholder[data-v-230b1657]{
  transform:translate3d(0, -60%, 0);
  color:#00aafa
}
.city-selection-popup__city-dadata[data-v-230b1657]{
  box-sizing:border-box;
  border-radius:0px;
  text-align:left;
  overflow:auto;
  height:35vh
}
@media(max-width: 640px){
.city-selection-popup__city-dadata[data-v-230b1657]{
    height:30vh
}
}
.city-selection-popup__city-dadata li[data-v-230b1657]{
  cursor:pointer;
  padding:7px 20px;
  display:flex;
  flex-direction:column;
  border:1px solid #f3f3f3;
  border-bottom:none;
  margin:0
}
.city-selection-popup__city-dadata li[data-v-230b1657]:last-child{
  border-bottom:1px solid #f3f3f3
}
.city-selection-popup__city-dadata li[data-v-230b1657]:before{
  content:none
}
.city-selection-popup__city-dadata span[data-v-230b1657]{
  color:#373737
}
.city-selection-popup__city-dadata span[data-v-230b1657]:first-child{
  font-weight:600;
  font-size:14px;
  line-height:19px
}
.city-selection-popup__city-dadata span[data-v-230b1657]:last-child{
  font-weight:400;
  font-size:12px;
  line-height:16px
}
.city-selection-popup--mobile .city-selection-popup__city li[data-v-230b1657]{
  padding:12px 0
}
.city-selection-popup--mobile .city-selection-popup__input-wrapper[data-v-230b1657]{
  margin:28px 0 3px
}
.city-selection-popup--mobile .city-selection-popup__input[data-v-230b1657]{
  background:#f2f5f7;
  outline:0;
  padding:16px 17px;
  border:none
}
.city-selection-popup--mobile .city-selection-popup__input:focus+.city-selection-popup__placeholder[data-v-230b1657]{
  display:none
}
.city-selection-popup--mobile .city-selection-popup__closeBtn[data-v-230b1657]{
  width:18px;
  height:18px;
  top:16px;
  background:#c4c4c4;
  border-radius:50px
}
.city-selection-popup--mobile .city-selection-popup__closeBtn[data-v-230b1657]::before,.city-selection-popup--mobile .city-selection-popup__closeBtn[data-v-230b1657]::after{
  height:10px;
  width:1.5px;
  top:4px;
  left:8px;
  background:#fff
}
.delivery__header[data-v-230b1657]{
  position:relative;
  -webkit-tap-highlight-color:transparent;
  -webkit-tap-highlight-color:rgba(0,0,0,0)
}
.delivery__header .delivery__icon[data-v-230b1657]{
  display:none
}
.delivery__title[data-v-230b1657]{
  font-size:18px;
  line-height:32px;
  font-weight:600;
  display:flex;
  align-items:center;
  flex-flow:row wrap;
  margin-bottom:0;
  margin-top:0
}
.delivery__title--pointer[data-v-230b1657]{
  cursor:pointer
}
.delivery__text[data-v-230b1657]{
  font-weight:600;
  font-size:16px;
  color:#373737;
  margin-right:8px;
  text-transform:none;
  line-height:1.3
}
.delivery__text-description[data-v-230b1657]{
  white-space:nowrap;
  margin-right:5px
}
.delivery__text--blue[data-v-230b1657]{
  color:#00aafa
}
.delivery__text-city-wrapper[data-v-230b1657]{
  display:inline-flex;
  align-items:center;
  white-space:pre-line
}
.delivery__text--button[data-v-230b1657]{
  margin-right:10px;
  display:flex;
  justify-content:space-around;
  align-items:center;
  border:0;
  -webkit-tap-highlight-color:rgba(73,166,219,0.46667) !important;
  outline:none
}
.delivery__need-mobile .delivery__icon[data-v-230b1657]{
  cursor:pointer;
  display:block
}
.delivery__need-mobile .delivery__title[data-v-230b1657]{
  display:none
}
@media(min-width: 1280px){
.delivery__need-mobile .delivery__icon[data-v-230b1657]{
    display:none
}
.delivery__need-mobile .delivery__title[data-v-230b1657]{
    display:flex
}
}
.header_city[data-v-230b1657]{
  display:none;
  position:relative;
  padding:0 0 0 0;
  background:none;
  border:none;
  color:#00aafa;
  -webkit-tap-highlight-color:rgba(73,166,219,0.46667)
}
.header_city[data-v-230b1657]:hover{
  color:#e2e2e2
}
.header_city:hover .icon-city path[data-v-230b1657]{
  stroke:#e2e2e2
}
.header_city:hover .icon-city circle[data-v-230b1657]{
  stroke:#e2e2e2;
  fill:#e2e2e2
}
.header_city[data-v-230b1657]:focus{
  outline:none
}
@media(max-width: 1280px){
.delivery__in-header .header_city[data-v-230b1657]{
    display:inline-block
}
}
.delivery__in-header .delivery__title[data-v-230b1657]{
  display:none
}
@media(max-width: 1280px){
.mobile__hidden[data-v-230b1657]{
    display:none
}
}