.acuvue-link{
  border-bottom:none;
  position:absolute;
  right:56px;
  bottom:0
}

.acuvue-link--pending{
  opacity:.5
}

.header .acuvue-bonus__link{
  text-decoration:none;
  display:flex;
  align-items:center;
  position:relative;
  border:none
}

.header .acuvue-bonus__link:hover{
  border:none
}

.header .acuvue-bonus__link:hover .acuvue-bonus__tooltip{
  display:block
}

.header .acuvue-bonus__icon--arrows{
  margin-right:6px
}

.header .acuvue-bonus__icon--pending{
  opacity:.5
}

.header .acuvue-bonus__tooltip{
  display:none;
  font-size:12px;
  line-height:22px;
  position:absolute;
  top:calc(100% + 8px);
  left:50%;
  transform:translateX(-50%);
  width:100px;
  min-width:-webkit-max-content;
  min-width:max-content;
  border:1px solid #373737;
  border-radius:5px;
  background:#fff;
  z-index:10;
  padding:10px 15px;
  text-align:center;
  box-sizing:border-box
}

.header .acuvue-bonus__tooltip::before{
  content:"";
  position:absolute;
  width:12px;
  height:12px;
  display:block;
  top:-7px;
  left:50%;
  transform:translateX(-50%) rotate(45deg);
  border-left:1px solid #373737;
  border-top:1px solid #373737;
  background:#fff;
  border-top-left-radius:5px
}

.header .acuvue-bonus__tooltip--error{
  color:#ee4981;
  border:1px solid #ee4981
}

.header .acuvue-bonus__tooltip--error::before{
  border-left:1px solid #ee4981;
  border-top:1px solid #ee4981
}

.header .acuvue-bonus__tooltip-text{
  margin:0;
  line-height:1
}

.header .acuvue-bonus__tooltip-text--title{
  color:#373737;
  margin-bottom:10px
}

.header .acuvue-bonus__tooltip-text--balance{
  color:#002f86
}

.acuvue-bonus__link--error:after,.acuvue-link--error:after{
  position:absolute;
  top:-6px;
  right:-5px;
  content:"!";
  width:15px;
  height:15px;
  background:#ee4981;
  border-radius:9px;
  color:#fff;
  font-weight:600;
  text-align:center;
  font-size:7px;
  line-height:11px;
  border:2px solid #fff
}