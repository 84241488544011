#status-messages[data-v-4903ca77]{
  position:fixed;
  top:0px;
  left:0;
  right:0;
  margin:0px auto;
  z-index:1300000;
  font-size:12px;
  width:400px;
  border-bottom-left-radius:5px;
  border-bottom-right-radius:5px;
  background-color:#eeede8;
  color:#1b5d8e
}
#status-messages ul[data-v-4903ca77]{
  border-bottom-left-radius:5px;
  border-bottom-right-radius:5px;
  margin:0;
  padding:0
}
#status-messages ul li[data-v-4903ca77]{
  list-style:none;
  line-height:normal;
  padding-left:0;
  margin-bottom:0;
  color:inherit;
  position:static;
  padding-left:1em
}
#status-messages ul li[data-v-4903ca77]:last-child{
  border-bottom-left-radius:5px;
  border-bottom-right-radius:5px
}
#status-messages ul li[data-v-4903ca77]:before{
  display:none
}
#status-messages span.close[data-v-4903ca77]{
  display:block;
  position:absolute;
  right:8px;
  top:5px;
  width:15px;
  height:15px;
  padding:0px;
  background:transparent none;
  cursor:pointer
}
#status-messages span.close img[data-v-4903ca77]{
  background:transparent url(/frontend-new/components/TopMessages/images/icon_cross_white.png) no-repeat left top;
  width:15px;
  height:15px;
  margin:0px;
  padding:0px
}
#status-messages li[data-v-4903ca77]{
  list-style:none;
  background-image:none;
  padding:5px 31px 6px 1em;
  margin:0px
}
#status-messages li em[data-v-4903ca77]{
  margin-right:6px
}
#status-messages span[data-v-4903ca77]{
  background-color:#eeede8;
  color:#1b5d8e
}
#status-messages .info[data-v-4903ca77]{
  background-color:#bae8aa;
  color:#374334
}
#status-messages .info span[data-v-4903ca77]{
  background-color:#bae8aa;
  color:#374334
}
#status-messages .warning[data-v-4903ca77]{
  background-color:#eedeb4;
  color:#94572a
}
#status-messages .warning span[data-v-4903ca77]{
  background-color:#eedeb4;
  color:#94572a
}
#status-messages .error[data-v-4903ca77]{
  background-color:#e65e57;
  color:#fff
}
#status-messages .error span[data-v-4903ca77]{
  background-color:#e65e57;
  color:#fff
}
@media screen and (max-width: 640px){
#status-messages[data-v-4903ca77]{
    width:100%
}
}