@media(min-width: 1280px){
.header__nav-link--relative{
    position:relative;
    display:flex;
    margin-right:9px
}
.header__nav-link-counter{
    position:absolute;
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    width:auto;
    min-width:17px;
    height:17px;
    font-size:11px;
    color:#fff;
    border-radius:8.65px 8.65px 8.65px 0;
    background-color:#00aafa;
    top:-12px;
    right:initial;
    left:22px;
    padding:0 2px
}
}