.preloader-wrap{
  width:100%;
  height:100vh;
  position:fixed;
  top:0;
  left:0;
  background-color:rgba(55,55,55,.2);
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  z-index:11000
}
.dots{
  display:flex;
  margin-top:15px
}
.dots div{
  border-radius:50%;
  background-color:#00aafa;
  width:20px;
  height:20px;
  transition:background-color 350ms linear
}
.dots div:not(:last-child){
  margin-right:10px
}